import {
	ApolloClient,
	createHttpLink,
	InMemoryCache,
	NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { getAuthUserToken } from '../utils/auth';
import { querySettings } from '../utils/queryUtils';

export const getApolloClient = async (): Promise<
	ApolloClient<NormalizedCacheObject>
> => {
	const settings = await querySettings();
	const apolloUri = settings.apollo.url;

	const httpLink = createHttpLink({
		uri: apolloUri,
		credentials: 'include',
		preserveHeaderCase: true,
	});

	const authLink = setContext(async (_, { headers }) => {
		const jwt = await getAuthUserToken();
		return {
			headers: {
				...headers,
				Authorization: jwt ? `Bearer ${jwt}` : '',
			},
		};
	});

	const retryLink = new RetryLink({
		delay: {
			initial: 300, // Start with 300ms delay
			max: 3000, // Maximum delay of 3s
			jitter: true, // Add randomness to prevent thundering herd
		},
		attempts: {
			max: 5, // Maximum number of retry attempts
			retryIf: (error, _operation) => {
				// Don't retry on authentication or validation errors
				if (error.message.match(/NOT_AUTHORIZED|RESOURCE_NOT_FOUND/)) {
					return false;
				}
				// Retry on network errors and other GraphQL errors
				return !!error;
			},
		},
	});

	return new ApolloClient({
		link: authLink.concat(retryLink).concat(httpLink),
		cache: new InMemoryCache(),
	});
};
