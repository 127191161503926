/* eslint-disable no-console */
import { allowedOrigins } from '../constants/security';
console.log('Custom addEventListener override script is running!');
(function () {
	let originalAddEventListener = window.addEventListener; // Store original function
	function secureAddEventListener(type, listener, options) {
		if (type === 'message') {
			console.warn('postMessage listener added:', listener.toString());
			const secureListener = function (event) {
				if (!allowedOrigins.includes(event.origin)) {
					console.warn(
						'Blocked untrusted postMessage from:',
						JSON.stringify(event.origin),
					);
					return;
				}
				listener(event);
			};
			originalAddEventListener.call(this, type, secureListener, options);
		} else {
			originalAddEventListener.call(this, type, listener, options);
		}
	}
	// Prevent scripts from modifying addEventListener for "message" events
	Object.defineProperty(window, 'addEventListener', {
		configurable: true,
		get: function () {
			return secureAddEventListener;
		},
		set: function (newFn) {
			originalAddEventListener = function (type, listener, options) {
				if (type === 'message') {
					secureAddEventListener(type, listener, options);
				} else {
					newFn.call(this, type, listener, options);
				}
			};
		},
	});
	console.log(
		"Custom addEventListener override applied for 'message' events!",
	);
})();
