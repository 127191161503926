/**
 @file security.js
@description Defines security-related constants used across the application.
This file centralizes all security-related configurations, such as
allowed origins for `postMessage` security filtering.
Keeping these values in one place improves maintainability and prevents
hardcoded values scattered across multiple files.
If updates are needed, modify this file instead of updating individual scripts.
Usage:
import { allowedOrigins } from '../constants/security';
 */
export const allowedOrigins = [
	'https://beta.learnerhub.skillbuilder.training.aws.dev',
	'https://learnerhub.skillbuilder.training.aws.dev',
	'https://learnerhub.skillbuilder.aws',
	'https://us-east-1.quicksight.aws.amazon.com',
];
